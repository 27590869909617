import React, { useEffect, useState } from "react";
import axios from "axios";
import omniaService from "../services/omnia";
import "../assets/css/sucursales.css"
import icono1 from "../assets/img/gasperla-icono-maneja-con-precaucion.png";
import icono2 from "../assets/img/gasperla-icono-apaga-tu-motor.png";
import icono3 from "../assets/img/gasperla-icono-no-utilices-tu-celular.png";
import icono4 from "../assets/img/gasperla-icono-no-fumar.png";
import img1 from "../assets/img/gasperla-imagen-servicio-estacionario.jpg";
import img2 from "../assets/img/gasperla-imagen-servicio-cilindros.jpg";
import footer from "../assets/img/gas-perla-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Sucursales = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }
        if (!error && lead.servicio.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            axios.post('https://omnia.futurite.com/api/save-lead-form', data)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="sucursales">
            {/* META TAGS */}
            <Helmet>
                <title>Gas Perla</title>
                <meta name="description" content="" />
                <link rel="canonical" href={currentUrl} />
                <script type="application/ld+json">
                    {`
                    
                    `}
                </script>
            </Helmet>
            
            <Header />

            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white">
                            <h1 className="fw-bold fs-50"> Sucursales </h1>
                            <a href="#contacto" className="btn-cta mt-5 mb-5"> REALIZA TU PEDIDO </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2 d-pad">
                <div className="container" id="sucursales-sec">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="mt-4 fw-bold ff-lato fs-18">
                                Te mostramos el mapa con las ubicaciones de nuestras estaciones de carburación.<br />
                                Visita la sucursal más cercana a ti.
                            </p>
                            <p className="ff-lato fw-bold fs-26 mb-5"> ¡Te esperamos! </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <iframe className="w-100"
                                src="https://www.google.com/maps/d/u/0/embed?mid=1nRz3snEUea_-kUpifyDSiEx1T62neSI&ehbc=2E312F"
                                height="600"></iframe>
                        </div>
                    </div>
                    <div className="row" role="tablist">
                        <div className="col-md-3 text-center">
                            <button className="btn mt-5 active" id="sucursal-tab-1" data-bs-toggle="tab"
                                data-bs-target="#sucursal-1" type="button" role="tab" aria-controls="sucursal-1"
                                aria-selected="true">
                                EL VERDE
                            </button>
                        </div>
                        <div className="col-md-3 text-center">
                            <button className="btn mt-5" id="sucursal-tab-2" data-bs-toggle="tab"
                                data-bs-target="#sucursal-2" type="button" role="tab" aria-controls="sucursal-2"
                                aria-selected="false">
                                SAN PEDRO TLAQUEPAQUE
                            </button>
                        </div>
                        <div className="col-md-3 text-center">
                            <button className="btn mt-5" id="sucursal-tab-3" data-bs-toggle="tab"
                                data-bs-target="#sucursal-3" type="button" role="tab" aria-controls="sucursal-3"
                                aria-selected="false">
                                ZAPOPAN
                            </button>
                        </div>
                        <div className="col-md-3 text-center">
                            <button className="btn mt-5" id="sucursal-tab-4" data-bs-toggle="tab"
                                data-bs-target="#sucursal-4" type="button" role="tab" aria-controls="sucursal-4"
                                aria-selected="false">
                                TLAJOMULCO DE ZUÑIGA
                            </button>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane show active" id="sucursal-1" role="tabpanel" aria-labelledby="sucursal-tab-1"
                            tabindex="0">
                            <div className="row">
                                <div className="col-md-3 d-flex">
                                    <div className="mt-4" >
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación El Verde </p>
                                            <p> Reforma 51, El Verde Jalisco </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="sucursal-2" role="tabpanel" aria-labelledby="sucursal-tab-2"
                            tabindex="0">
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="mt-4">
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Colon </p>
                                            <p>
                                                Santa María Tequepexpan, <br />
                                                45601 San Pedro <br />
                                                Tlaquepaque, Jal.
                                            </p>
                                            <p>
                                                LUNES A DOMINGO <br />
                                                7:00 A.M. - 9:00 P.M.
                                            </p>
                                            <p className="fw-bold"> +523331510381 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="sucursal-3" role="tabpanel" aria-labelledby="sucursal-tab-3"
                            tabindex="0">
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="mt-4">
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Perla </p>
                                            <p>
                                                Carretera Guadalajara-Tepic <br />
                                                #17400 La venta del Astillero <br />
                                                45221, Zapopan, Jal.
                                            </p>
                                            <p>
                                                LUNES A SÁBADO <br />
                                                7:00 A.M. - 7:00 P.M.
                                            </p>
                                            <p>
                                                DOMINGO <br />
                                                CERRADO
                                            </p>
                                            <p className="fw-bold"> +523331510381 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="sucursal-4" role="tabpanel" aria-labelledby="sucursal-tab-4"
                            tabindex="0">
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="mt-4">
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Hacienda Santa Fe </p>
                                            <p>
                                                Boulevard Colon #L-3 int M. <br />
                                                Haciendas Santa Fe, 45653, <br />
                                                Tlajomulco de Zuñiga, Jalisco CP.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC3 */}
            <div className="sec3 d-pad bg-grey">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-5 fs-25 text-grey"> Recomendaciones al momento de estar en una estación de carburación. </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono1} className="w-25" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono2} className="w-25" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono3} className="w-25" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono4} className="w-25" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono1} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> Maneja con precaución y a <br /> baja velocidad </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono2} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> Apaga tu motor antes de <br /> que inicie la carga </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono3} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> No utilices tu celular </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono4} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> No fumes </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC4 */}
            <div className="sec4 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2 className="fw-bold mb-4 fs-25 ff-lato text-grey text-center"> Conoce nuestros servicios. </h2>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6 mobile-item">
                            <img src={img1} className="w-100" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="fw-bold fs-25 ff-lato mt-md-0 mt-3"> Estacionario </h2>
                            <p className="mt-3"> Llevamos el gas lp hasta tu hogar o negocio <br /> mediante nuestras unidades especializadas. </p>
                            <Link to={'/gas-estacionario'} className="btn-cta mt-4 mb-md-0 mb-5">MÁS INFORMACIÓN</Link>
                        </div>
                        <div className="col-md-6 desktop-item">
                            <img src={img1} className="w-100" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <img src={img2} className="w-100" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="fw-bold fs-25 ff-lato mt-md-0 mt-3"> Cilindros </h2>
                            <p className="mt-3"> Llevamos el gas lp hasta tu hogar o negocio mediante <br /> nuestras unidades especializadas. </p>
                            <Link to={'/cilindros'} className="btn-cta mt-4">MÁS INFORMACIÓN</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC5 */}
            <div className="sec5 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fw-bold mt-4 mb-4 fs-25 ff-lato text-center desktop-item text-light-grey"> ESCRÍBENOS, ESTAMOS PARA ATENDERTE </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"> </div>
                        <div className="col-md-6">
                            <p className="fw-bold mb-4 fs-25 ff-lato text-center mobile-item mt-4">Escríbenos, estamos para atenderte</p>
                            <form className="bg-dark-grey" onSubmit={onSubmitHandler}>
                                <input type="text" name="nombre" placeholder="Nombre:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.nombre || ""} required />
                                <input type="email" name="email" placeholder="Email:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.email || ""} required />
                                <input type="text" name="servicio" placeholder="Servicio:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.servicio || ""} required />
                                <input type="tel" name="telefono" placeholder="Télefono" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.telefono || ""} required />
                                <textarea rows={5} placeholder="Mensaje:" name="mensaje" className="form-control rounded-0 mb-4"
                                    onChange={handleChange} value={lead.mensaje || ""} ></textarea>
                                <button type="submit" className="btn-cta fw-bold"> ENVIAR </button>
                            </form>
                        </div>
                        <div className="col-md-3"> </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Sucursales;