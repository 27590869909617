import React, { useEffect, useState } from "react";
import axios from "axios";
import omniaService from "../services/omnia";
import "../assets/css/home.css"
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import waIcon from "../assets/img/logo-wa.png";
import logoB from "../assets/img/perla-alt-white.png";
import phone from "../assets/img/logo-tel-blanco.png";
import nosotros from "../assets/img/gas-perla-nosotros.png";
import estacionario from "../assets/img/gas-perla-servicio-estacionario.jpg";
import cilindros from "../assets/img/gas-perla-servicio-cilindros.jpg";
import footer from "../assets/img/gas-perla-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});

    useEffect(() => {
        new Glide('.glide', { type: 'slider', perView: 1 }).mount();
    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }
        if (!error && lead.servicio.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            axios.post('https://omnia.futurite.com/api/save-lead-form', data)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="home">
            <Helmet>
                <title>Gas Perla</title>
                <meta name="description" content="" />
                <link rel="canonical" href={currentUrl} />
                <script type="application/ld+json">
                    {`
                    
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="container-fluid sec1 p-0">
                <div className="glide position-relative">
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides text-white">
                            <div className="glide__slide slide1"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide2"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide3"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide4"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide5"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide6"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide7"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide8"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide9"><div className="w-100 h-100 op-grey"></div></div>
                        </div>
                    </div>
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        <button className="glide__bullet" data-glide-dir="=0"></button>
                        <button className="glide__bullet" data-glide-dir="=1"></button>
                        <button className="glide__bullet" data-glide-dir="=2"></button>
                        <button className="glide__bullet" data-glide-dir="=3"></button>
                        <button className="glide__bullet" data-glide-dir="=4"></button>
                        <button className="glide__bullet" data-glide-dir="=5"></button>
                        <button className="glide__bullet" data-glide-dir="=6"></button>
                        <button className="glide__bullet" data-glide-dir="=7"></button>
                        <button className="glide__bullet" data-glide-dir="=8"></button>
                    </div>
                    <div className="glide__arrows" data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                            <span className="icon-hero-arrow-left text-white"></span>
                        </button>
                        <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                            <span className="icon-hero-arrow-right text-white"></span>
                        </button>
                    </div>
                </div>
                <div className="text-center text-white position-absolute top-50 start-50 translate-middle w-75">
                    <p className="mb-2">Servicio de distribución de Gas Lp</p>
                    <h1 className="fw-bold fs-50">Tu mejor opción en Gas LP</h1>
                </div>
            </div>

            <div className="sec2 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-4">Conoce más sobre el gas lp</h2>
                            <p className="mb-5 ff-lato">
                                El gas licuado de petróleo, comúnmente conocido como gas lp, es un hidrocarburo compuesto <br /> principalmente por propano y butano. Estos componentes se extraen durante el proceso de <br /> fraccionamiento del petróleo y el gas natural.
                            </p>
                            <p>
                                Algunas de las características más distintivas del gas lp son:
                            </p>
                            <p>
                                • Combustión casi completa<br />
                                • Incoloro y transparente<br />
                                • Baja toxicidad<br />
                                • Alto rendimiento<br />
                            </p>
                            <a href="#contacto" className="btn-cta mt-5">REALIZA TU PEDIDO</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec3 d-pad bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-5 fs-25">VENTAJAS DE USAR GAS LP PARA USO INDUSTRIAL, DOMÉSTICO Y VEHICULAR</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <span className="icon-flames icon fs-50 text-red me-3"></span>
                                <p className="mb-0">
                                    Ahorro de tiempo y consumo gracias a su alto poder calorífico.
                                </p>
                            </div>

                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <span className="icon-flames icon fs-50 text-red me-3"></span>
                                <p className="mb-0">
                                    Las emisiones de contaminantes regulados son muy bajas.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <span className="icon-tank icon fs-50 text-red me-3"></span>
                                <p className="mb-0">
                                    Se puede almacenar hasta dos meses de consumo.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <span className="icon-tank icon fs-50 text-red me-3"></span>
                                <p className="mb-0">
                                    Es una fuente de energía económica y versátil.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec4 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-5 text-grey">Nuestros servicios en gas lp</h2>
                            <h2 className="fw-bold mb-3 ff-lato">ESTACIONARIO</h2>
                            <p className="mb-5">
                                Llevamos el gas lp hasta tu hogar o negocio mediante
                                nuestras unidades especializadas. <br /> Suministro directo
                                a tu tanque estacionario a domicilio.
                            </p>
                            <div className="image-box position-relative text-center">
                                <img src={estacionario} alt="servicio de gas estacionario-gas estacionario 1" className="w-100" />
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                    <Link to={'/gas-estacionario'} className="btn-cta">MÁS INFORMACIÓN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec5 bg-grey text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-3 ff-lato">CILINDROS</h2>
                            <p className="mb-5">
                                Recarga tus cilindros con gas lp en cualquiera
                                de nuestras sucursales, donde personal<br />
                                capacitado se encargará de ayudarte.
                            </p>
                            <div className="image-box position-relative text-center">
                                <img src={cilindros} className="w-100" alt="" />
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                <Link to={'/cilindros'} className="btn-cta">MÁS INFORMACIÓN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec7 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bg-grey ticketbox">
                                <span className="icon-tickets-color icon text-red fs-50"></span>
                                <h2 className="fw-bold mb-3 ff-lato">TICKETS PERSONALIZADOS</h2>
                                <p>
                                    Identificación de nuestros clientes a través de RFID.
                                    Siempre obtendrá<br /> un ticket con fecha, hora, litros
                                    descargados, importe total y la unidad<br /> que lo realizó.
                                </p>
                                <a href="#contacto" className="btn-cta mt-3">FACTURACIÓN</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sec10 container-fluid">
                <div class="container bg">
                    <h2 class="pt-5">HORARIOS</h2>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 dias">
                                <p class="subtitle">LUNES A VIERNES</p>
                                <p class="am">7:00 AM • 7:00 PM</p>

                                <p class="subtitle mt-4">SÁBADOS Y FESTIVOS</p>
                                <p class="am">7:00 AM • 4:00 PM</p>
                            </div>
                            <div class="col-md-6">
                                <p class="subtitle">FORMAS DE PAGO</p>
                                <div class="flexItems">
                                    <span class="icon icon-cash"></span>
                                    <p class="hora mt-3">Efectivo</p>
                                </div>

                                <div class="flexItems">
                                    <span class="icon icon-credit-card"></span>
                                    <p class="hora mt-3">Tarjeta de Crédito y Débito <br /> <span class="sub">Visa y MasterCard</span> </p>
                                </div>

                                <div class="flexItems">
                                    <span class="icon icon-transfer"></span>
                                    <p class="hora mt-3">Transferencia Electrónica</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-pad text-center" id="sucursales">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold fs-58 mb-4 ls-25">SUCURSALES</h2>
                            <p className="ls-25 mb-5">ENCUENTRA TU SUCURSAL MÁS CERCANA.</p>

                            <iframe className="w-100" title="mapa"
                                src="https://www.google.com/maps/d/u/0/embed?mid=1nRz3snEUea_-kUpifyDSiEx1T62neSI&ehbc=2E312F"
                                height="600"></iframe>

                        </div>
                    </div>
                </div>
            </div>

            <div className="sec9 d-pad bg-grey" id="nosotros">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold fs-58 mb-3 ls-25 text-center">NOSOTROS</h2>
                            <p className="ls-25 text-center">
                                Somos una empresa dedicada a la distribución de Gas LP con más de 60 años.
                            </p>
                            <div className="us-box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>
                                            Comercializamos y suministramos <strong> Gas LP </strong>  para soluciones industriales y residenciales.
                                        </p>
                                        <p>
                                            Contamos con más de 20 autotanques disponibles a su servicio los 365 días del año. Además de 4 estaciones de carburación para realizar recargas de automóviles.
                                        </p>
                                        <p>
                                            Nuestra integridad, respeto y excelente servicio nos distinguen.
                                        </p>
                                        <a href="#contacto" className="btn-cta mt-3">REALIZA TU PEDIDO</a>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={nosotros} className="w-100 img-nosotros" alt="" />
                                    </div>
                                </div>
                            </div>
                            <p className="ls-25 mt-5 fw-bold fs-18 text-md-start text-center">NUESTROS VALORES</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-integrity-color icon-circle"></span>
                                <p className="ls-25 text-red box-title fw-bold">INTEGRIDAD</p>
                                <p className="hover-text">
                                    Cumplimos nuestra<br /> palabra.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-service-color icon-circle"></span>
                                <p className="ls-25 text-red box-title fw-bold">SERVICIO</p>
                                <p className="hover-text">
                                    Nuestro diferenciador.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-excellence-color icon-circle"></span>
                                <p className="ls-25 text-red box-title fw-bold">EXCELENCIA</p>
                                <p className="hover-text">
                                    Aunque no perfectos, buscando
                                    siempre la mejora.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-humility-color icon-circle"></span>
                                <p className="ls-25 text-red box-title fw-bold">HUMILDAD</p>
                                <p className="hover-text">
                                    Aceptando oportunidades con
                                    el reto de mejora.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-respect-color icon-circle"></span>
                                <p className="ls-25 text-red box-title fw-bold">RESPETO</p>
                                <p className="hover-text">
                                    Con dignidad para nuestros clientes,
                                    empleados, familias y comunidades.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqs">
                <div className="container">
                    <h3 className="fw-bold mb-5">PREGUNTAS FRECUENTES SOBRE NUESTRO SERVICIO.</h3>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Cómo se entrega el gas LP a mi domicilio o negocio?
                                </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    La entrega de <strong> gas LP </strong> se realiza por medio de un pedido por llamada o WhatsApp para programar el servicio. Las unidades acuden a los domicilios, son monitoreadas hasta surtir el servicio y se entrega ticket personalizado.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Cómo puedo saber si necesito rellenar mi tanque de gas LP?
                                </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Cuando la flama tiene muy poca intensidad o no sale absolutamente nada en los cilindros, en tanques estacionarios monitoreando el indicador de nivel que no llegue a 10% de capacidad
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿Qué formas de pago aceptan para la compra de gas LP?
                                </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Las formas de pago aceptadas para la compra de gas lp son efectivo, transferencia electrónica, así como tarjeta de crédito y débito (Visa y MasterCard).
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Cuál es el horario de entrega?
                                </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Nuestro horario de entrega está diseñado para resultar conveniente y oportuno, por ello abarcamos sábados y días festivos: <br /><br />
                                    <strong>- Lunes a viernes</strong><br />
                                    7:00 AM a 7:00 PM<br /><br />
                                    <strong>- Sábados y Festivos</strong><br />
                                    7:00 AM a 4:00 PM
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    ¿Qué sucede si no estoy en casa cuando llega el camión de entrega?
                                </button>
                            </h3>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Se hace una llamada telefónica al cliente y el servicio se pospone para otro día u horario. Es necesario que estés presente cuando se surte el pedido.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    ¿Cómo puedo contratar el servicio de suministro de gas LP?
                                </button>
                            </h3>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Contratar nuestro servicio de <strong> gas LP </strong> es muy sencillo, solamente necesitas llamarnos o enviarnos un mensaje de whatsapp al número: (81) 8360 1100 o bien llenar el formulario que se encuentra al final de esta página, uno de nuestros asesores le responderá para realizar la contratación.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    ¿Cuánto tiempo tarda en llegar mi pedido de gas LP?
                                </button>
                            </h3>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    El tiempo estimado es durante el transcurso del día y se programa con base en las rutas de las unidades.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec12 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mb-5">Obtén un suministro <br /> constante de Gas LP para <br /> tu hogar o negocio.</h2>
                            <img src={footer} alt="servicio de gas estacionario-gas estacionario 2" />
                        </div>
                        <div className="col-md-5">
                            <p className="mb-4">
                                ESCRÍBENOS, ESTAMOS PARA ATENDERTE
                            </p>
                            <div className="contacto" id="form">
                                <form onSubmit={onSubmitHandler}>
                                    <input type="text" name="nombre" placeholder="Nombre:" onChange={handleChange} value={lead.nombre || ""} required />
                                    <input type="text" name="email" placeholder="Email:" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="servicio" placeholder="Servicio:" onChange={handleChange} value={lead.servicio || ""} required />
                                    <input type="text" name="telefono" placeholder="Teléfono:" onChange={handleChange} value={lead.telefono || ""} required />
                                    <textarea name="mensaje" placeholder="Mensaje:" onChange={handleChange} value={lead.mensaje || ""} required />
                                    <button type="submit" className="btn-cta mt-3">Enviar</button>
                                </form>
                            </div>
                        </div>
                        <div className="coml-md-1"></div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Home;