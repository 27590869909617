import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import waIcon from "../../assets/img/logo-wa.png";
import logoB from "../../assets/img/perla-alt-white.png";
import logo from "../../assets/img/perla-alt.png";
import mobilelogoB from "../../assets/img/perla-alt-white.png";
import mobilelogo from "../../assets/img/perla-alt.png";
import fbIcon from "../../assets/img/icono-facebook.png";
import fbred from "../../assets/img/icono-facebook-hov.png";
import twIcon from "../../assets/img/icono-twitter.png";
import twred from "../../assets/img/icono-twitter-hov.png";
import igIcon from "../../assets/img/icono-instagram.png";
import igred from "../../assets/img/icono-instagram-hov.png";


const Header = () => {
    const btnNav = React.useRef(null);
    const headerNav = React.useRef(null);

    useEffect(() => {
        changeMenuBg()
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const handleScroll = (event) => {
        changeMenuBg()
    };

    const handleNavBtn = () => {
        let menuText = headerNav.current.querySelectorAll('.menu-text');
        let whiteLogo = headerNav.current.querySelectorAll('.white-logo');
        let blueLogo = headerNav.current.querySelectorAll('.red-logo');
        let togglerIcon = headerNav.current.querySelector('.navbar-toggler-icon');

        if (btnNav.current.classList.contains('collapsed')) {
            changeMenuBg()
        } else {
            headerNav.current.classList.add("bg-white");
            togglerIcon.classList.remove("white-toggler");
            togglerIcon.classList.add("blue-toggler");

            whiteLogo.forEach(item => { item.style.display = "none"; });
            blueLogo.forEach(item => { item.style.display = "block" });
            menuText.forEach(item => {
                item.classList.remove("text-white");
                item.classList.add("text-red");
            });
        }
    };

    const changeMenuBg = () => {
        let menuText = headerNav.current.querySelectorAll('.menu-text');
        let whiteLogo = headerNav.current.querySelectorAll('.white-logo');
        let blueLogo = headerNav.current.querySelectorAll('.red-logo');
        let togglerIcon = headerNav.current.querySelector('.navbar-toggler-icon');

        if (window.scrollY > 50) {
            headerNav.current.classList.add("bg-white");
            togglerIcon.classList.remove("white-toggler");
            togglerIcon.classList.add("blue-toggler");
            whiteLogo.forEach(item => { item.style.display = "none"; });
            blueLogo.forEach(item => { item.style.display = "block" });

            menuText.forEach(item => {
                item.classList.remove("text-white");
                item.classList.add("text-red");
            });
        } else {
            headerNav.current.classList.remove("bg-white");
            togglerIcon.classList.add("white-toggler");
            togglerIcon.classList.remove("blue-toggler");

            whiteLogo.forEach(item => { item.style.display = "block"; });
            blueLogo.forEach(item => { item.style.display = "none" });
            menuText.forEach(item => {
                item.classList.add("text-white");
                item.classList.remove("text-red");
            });
        }
    }
    return (
        <header className="container-fluid header" ref={headerNav} id="header-nav">
            <div className="container desktop-item">
                <div className="row">
                    <div className="col-md-12">
                        <div className="top-menu d-flex align-items-center justify-content-between">
                            <div>
                                <Link to={'/'}>
                                    <img src={logoB} className="logo white-logo" alt="" />
                                    <img src={logo} className="logo red-logo" style={{ display: "none" }} alt="" />
                                </Link>

                            </div>
                            <div className="menu-box d-flex justify-content-end align-items-center">
                                <ul className="nav me-4 justify-content-end">
                                    <li className="nav-item">
                                        <Link to={'/gas-estacionario'} className="nav-link text-white fw-bold menu-text">
                                            ESTACIONARIO
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/cilindros'} className="nav-link text-white fw-bold menu-text">
                                            CILINDROS
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/sucursales'} className="nav-link text-white fw-bold menu-text">
                                            SUCURSALES
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-white fw-bold menu-text" href="#nosotros">NOSOTROS</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex mt-2">
                                <img src={waIcon} className="icon-wa me-2" alt="" />
                                <p className="text-white fs-13 menu-text">
                                    Envíanos un mensaje a WhatsApp<br />
                                    o Llámanos al: <a href="tel:523331510381" className="fw-bold menu-text" >(33) 3151 0381</a>
                                </p>
                            </div>
                            <div className="social-box me-4 justify-content-end text-end d-flex">
                                <a href="https://www.facebook.com/Gasperla">
                                    <img src={fbIcon} className="social-icon me-1" alt="" />
                                    <img src={fbred} className="social-icon red-logo me-1" alt="" />
                                </a>
                                <a href="https://twitter.com/gasperlaoficial">
                                    <img src={twIcon} className="social-icon me-1" alt="" />
                                    <img src={twred} className="social-icon red-logo me-1" alt="" />
                                </a>
                                <a href="#">
                                    <img src={igIcon} className="social-icon" alt="" />
                                    <img src={igred} className="social-icon red-logo me-1" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg mobile-item">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/#">
                        <img src={mobilelogoB} className="logo white-logo" alt="" />
                        <img src={mobilelogo} className="logo blue-logo" style={{ display: "none" }} alt="" />
                    </a>
                    <button onClick={handleNavBtn} ref={btnNav} className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon white-toggler"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to={'/gas-estacionario'} className="nav-link text-white fw-bold menu-text fs-12">ESTACIONARIO
                                </Link>
                            </li>
                            <li className="nav-item">
                            <Link to={'/cilindros'} className="nav-link text-white fw-bold menu-text fs-12">CILINDROS
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/sucursales'} className="nav-link text-white fw-bold menu-text fs-12">SUCURSALES
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white fw-bold menu-text fs-12" href="#nosotros">NOSOTROS</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
};

export default Header;